




















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Integration } from '@/models';

import IntegrationConfigMapping from '@/components/integration/IntegrationConfigMapping.vue';
import { unwrapError } from '@/lib/helpers';
import { isEmpty, pickBy } from 'lodash-es';

interface LedgerFixProps {
  office: string;
  option: Record<string, any>;
}

@Component({
  components: {
    IntegrationConfigMapping,
  },
})
export default class FixLedgerModal extends Vue {
  @Prop({ required: true })
  integration: Integration;

  @Prop({ default: false, type: Boolean })
  isSaving: boolean;

  loading: boolean = true;
  props: LedgerFixProps | null = null;

  async mounted(): Promise<void> {
    try {
      this.props = {
        option: Object.assign({}, this.integration.option),
        office: this.integration.office,
      };
      this.loading = false;
    } catch (e) {
      this.$toaster.error('Could not fetch integration config', unwrapError(e));
    }
  }

  async onSubmit(): Promise<void> {
    // Send only prop attributes linked to ledger/vat
    const props = {
      option: pickBy(this.props!.option, (cfg, name) =>
        ['ledgernumbers', 'vatcodes'].includes(name),
      ),
    };
    this.$emit('fixed', { name: this.integration.name, props });
  }

  close(): void {
    this.$emit('close');
  }

  translate(prop: string): string {
    const i18nKey = `settings.integrations.fields.${this.integration.name}.${prop}`;

    if (this.$i18n.te(i18nKey)) {
      return this.$i18n.tc(i18nKey).toString();
    }

    return prop;
  }

  updateMapping(
    path: string,
    event: { value: string | null; index: string },
  ): void {
    if (this.props === null) {
      return;
    }
    if (Array.isArray(this.props.option[path])) {
      this.props.option[path] = {};
    }
    this.props.option[path][event.index] = event.value;
  }

  updateAll(config: any, path: string, value: string): void {
    if (this.props === null) {
      return;
    }
    if (Array.isArray(this.props.option[path])) {
      this.props.option[path] = {};
    }
    for (const key of Object.keys(config.names)) {
      this.props.option[path][key] = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get currentConfigOptions() {
    if (!this.integration || !this.integration.config.option) return [];
    return pickBy(
      this.integration.config.option,
      (cfg, name) =>
        ['ledgernumbers', 'vatcodes'].includes(name) && !isEmpty(cfg.names),
    );
  }
}
