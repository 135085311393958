var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal is-active"},[_c('div',{staticClass:"modal-background",on:{"click":_vm.close}}),_c('div',{staticClass:"modal-content container"},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('div',{directives:[{name:"t",rawName:"v-t",value:('settings.integrations.settings'),expression:"'settings.integrations.settings'"}],staticClass:"card-header-title"})]),_c('div',{staticClass:"card-content"},[(!_vm.loading && _vm.integration && _vm.integration.config)?_c('form',{staticClass:"form is-clearfix",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._l((_vm.currentConfigOptions),function(cfg,name){return _c('div',{key:name,staticClass:"field"},[_c('label',{staticClass:"label",domProps:{"textContent":_vm._s(_vm.translate(name))}}),(
                _vm.$i18n.te(
                  'settings.integrations.help.' +
                    _vm.integration.name +
                    '.' +
                    name
                )
              )?_c('div',{directives:[{name:"t",rawName:"v-t",value:(
                'settings.integrations.help.' + _vm.integration.name + '.' + name
              ),expression:"\n                'settings.integrations.help.' + integration.name + '.' + name\n              "}],staticClass:"option-help"}):(cfg.type === 'mapping')?_c('div',{staticClass:"mapping-list"},[(
                  _vm.$i18n.te(
                    'settings.integrations.help.' +
                      _vm.integration.name +
                      '.mapping'
                  )
                )?_c('div',{directives:[{name:"t",rawName:"v-t",value:(
                  'settings.integrations.help.' +
                  _vm.integration.name +
                  '.mapping'
                ),expression:"\n                  'settings.integrations.help.' +\n                  integration.name +\n                  '.mapping'\n                "}],staticClass:"mapping-help"}):_vm._e(),_c('integration-config-mapping',{attrs:{"bulk-change":false,"config":cfg,"target":_vm.props.option[name]},on:{"update-mapping":function($event){return _vm.updateMapping(name, $event)},"update-all":function($event){return _vm.updateAll(cfg, name, $event)}}})],1):_vm._e()])}),_c('div',{staticClass:"buttons is-pulled-right"},[_c('button',{directives:[{name:"t",rawName:"v-t",value:('_.save'),expression:"'_.save'"}],staticClass:"button is-success",class:{ 'is-loading': _vm.isSaving },attrs:{"type":"submit","disabled":_vm.isSaving}})])],2):_vm._e()])])]),_c('button',{staticClass:"modal-close is-large",attrs:{"aria-label":"close"},on:{"click":_vm.close}})])}
var staticRenderFns = []

export { render, staticRenderFns }